* {
  box-sizing: border-box;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

html,
body {
  margin: 0;
}

.border-0 {
  border: 0;
}
.bg-indigo-50 {
  background-color: #eef2ff;
}
.shadow-lg,
.shadow-md {
  box-shadow: 0 0 transparent;
}

.p-2 {
  padding: 0.5rem;
}

.bg-white {
  background-color: rgba(255, 255, 255, 1);
}

.w-2\/4 {
  width: 50%;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-5 {
  padding: 1.25rem;
}

.select-all {
  user-select: all;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media all and (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media all and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media all and (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media all and (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.container {
  width: 100%;
}

.select-none {
  user-select: none;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.border-blue-300 {
  border-color: rgba(147, 197, 253, 1);
}

.border-dashed {
  border-style: dashed;
}
.border-t-4 {
  border-top-width: 4px;
}

.select-none {
  user-select: none;
}

.text-gray-500 {
  color: rgba(107, 114, 128, 1);
}

.inline-block {
  display: inline-block;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
small {
  font-size: 80%;
}

.text-gray-700 {
  color: rgba(55, 65, 81, 1);
}

.font-bold {
  font-weight: 700;
}
.m-0 {
  margin: 0;
}

.w-full {
  width: 100%;
}

a {
  text-decoration: none;
}

.p-4 {
  padding: 1rem;
}

.flex {
  display: flex;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.p-1 {
  padding: 0.25rem;
}

.border-2 {
  border-width: 2px;
}
.w-full {
  width: 100%;
}
.block {
  display: block;
}
.mb-4 {
  margin-bottom: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border-color: rgba(229, 231, 235, 1);
  border-style: solid;
}

.text-white {
  color: rgba(255, 255, 255, 1);
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.bg-indigo-500 {
  background-color: rgba(99, 102, 241, 1);
}
.rounded {
  border-radius: 0.25rem;
}
.text-indigo-600 {
  color: rgba(79, 70, 229, 1);
}

.font-bold {
  font-weight: 700;
}
.text-xl {
  font-size: 1.25rem;
}
.text-lg,
.text-xl {
  line-height: 1.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}

.list-decimal {
  list-style-type: decimal;
}
.ml-4 {
  margin-left: 1rem;
}
ol,
ul {
  list-style: none;
  padding: 0;
}

.text-indigo-400 {
  color: rgba(129, 140, 248, 1);
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    liberation mono, courier new, monospace;
}

.bg-green-600 {
  background-color: rgba(5, 150, 105, 1);
}
